<script lang="ts">
  import { onMount } from "svelte";
  import { unsubscribeForNewsletter } from "../lib/api";
  import helixSdk from "../lib/helixTracking";
  helixSdk.trackViewEvent({
    product: {
      viewId: "avregistrering",
    },
  });

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  // TODO:  we will likely not send listId in the future, only newsletter
  //        remove listId some time in the future
  const listId = urlParams.get("listId") ?? undefined;
  const unsubscribedListId = urlParams.get("unsubscribedListId") ?? undefined;
  const newsletter = urlParams.get("newsletter") ?? undefined;

  onMount(async () => {
    if (email && (listId || newsletter || unsubscribedListId)) {
      await unsubscribeForNewsletter(email, {
        listId,
        newsletter,
        unsubscribedListId,
      });
    }
  });
</script>

<div class="svt-newsletter_content">
  <h1>
    <img
      class="svt-newsletter_logo"
      src="/images/svtlogo-black_v3.png"
      alt="SVT logotyp"
    />
    Nyhetsbrev
  </h1>

  <p>
    Din prenumeration har upphört och du kommer inte längre få några utskick
    från oss.
  </p>
</div>
